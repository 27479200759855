.log-viewer {
    background-color: #000;
    color: #fff;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    font-family: monospace;
    width: 100%;
    position: relative;
}

.log-message {
    margin-bottom: 5px;
}

.log-message.debug {
    color: #bbb;
}

.log-message.info {
    color: #fff;
}

.log-message.warn {
    color: #ffa500;
}

.log-message.error {
    color: #f00;
}

.log-message.fatal {
    color: #f00;
    font-weight: bold;
}