.logo-image {
    max-width: 3.5rem;
    max-height: 3.5rem;
    margin-right: 1rem;
}

.terminal {
    position: relative;
    width: 100%;
    max-height: 500px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: auto;
    background-color: rgb(15, 15, 16);
    overflow-y: scroll;
}

.terminal p {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
    font-size: 0.7rem;
}

.terminal .warning {
    color: #ffc04c;
}

.terminal .error {
    color: #ff6254;
}